const getPlans = async () => {
    try {
        const url = window.location.href;
        const isLocalhost = url.includes('localhost');
        const isSafeSakay = url.includes('safesakay.bpimsapps.com');
        const isSafeSakayBpi = url.includes('safesakay.bpims.com');
        const [, stage, , flag] = url.split('.');

        const endpoint = isLocalhost
            ? "https://partners-api.dev.bpimsapps.com/plans/v3"
            : isSafeSakay
                ? "https://partners-api.uat.bpimsapps.com/plans/v3"
                : isSafeSakayBpi
                    ? `${flag ? 'https://partners-api' : 'https://fes'}.${flag ? `${stage}.` : ''}bpimsapps.com${flag ? '' : '/partners'}/plans/v3`
                    : `${flag ? 'https://partners-api' : 'https://fes'}.${flag ? `${stage}.` : ''}bpimsapps.com${flag ? '' : '/partners'}/plans/v3`;

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "params": {
                    "lineCd" : "PC",
                    "sublineCd": "GPA",
                    "planGrp": "BSS"
                }
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('getPlans api error', error);
        throw error;
    }
};

export default getPlans;
